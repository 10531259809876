import { HeroCard } from 'designsystem/components/2-molecules/Card/HeroCard';
import { LinkFeature } from 'features/Modules/Link';

export const HeroCardFeature: React.FC<Content.HeroCard> = ({ content, settings, ...props }) => {
	const { heading, subHeading: headingSubtext, text, link, picture } = content?.properties ?? {};
	const { fullWidthImage, hideImageOnMobile, imageAspectRatio, noTopMargin } = settings?.properties ?? {};

	const { altText, image } = picture?.[0]?.content?.properties ?? {};
	const {
		focalPoint,
		properties: { umbracoHeight, umbracoWidth },
		url,
	} = image ?? {};

	const altTextChecked = altText == '""' ? '' : altText;

	return (
		<HeroCard
			image={
				image && {
					altText: altTextChecked,
					aspectRatio: parseInt(umbracoHeight) / parseInt(umbracoWidth),
					focalPoint,
					height: parseInt(umbracoHeight),
					url,
					width: parseInt(umbracoWidth),
				}
			}
			content={{
				heading,
				headingSubtext,
				text,
				link: link ? (
					<LinkFeature url={link.url} name={link.name} target={link.target} style="arrow" isCTALink={settings?.properties?.isCTALink} />
				) : undefined,
			}}
			fullWidthImage={fullWidthImage}
			hideImageOnMobile={hideImageOnMobile}
			imageAspectRatio={imageAspectRatio?.length ? imageAspectRatio : undefined}
			noTopMargin={noTopMargin}
			{...props}
		/>
	);
};
